import React, { useState, useEffect, useRef } from 'react';
import bci from './images/bc.png';
import Header from './component/header';
import SideMenu2 from './component/sideMenu2';
import SideMenu2P from './component/sideMenu2P';
import SideMenu2I from './component/sideMenu2I';
import SideMenu2C from './component/sideMenu2C';
import SideMenu2V from './component/sideMenu2V';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { API_BASE_URL } from './config/apiConfig';
import { Toaster, toast } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faChevronDown, faBold, faItalic, faUnderline, faStrikethrough, faQuoteRight, faCode, faLink, faImage, faTextHeight, faListOl, faListUl, faSubscript, faSuperscript, faOutdent, faIndent, faAlignRight, faHeading } from '@fortawesome/free-solid-svg-icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { jwtDecode } from "jwt-decode";
import ImageResize from 'quill-image-resize-vue';
import Tooltip from './component/tooltip';
import ImagePopup from './component/cradduleModal';
import axios from 'axios';
import nspell from 'nspell';
import API_BASE_WEB_URL from './config/apiConfigW';
import HeaderIdeation from './component/headerIdeation';
import ModalVideo from './component/modalNewVideo';


function QuestionBusIntro() {
  const [activeLink, setActiveLink] = useState("");
  const [activeId, setActiveId] = useState("");
  const [activeTime, setActiveTime] = useState("");
  const navigate = useNavigate()
  const { phase } = useParams();
  const onClickHandler = () => navigate(`/video`);
  const [images, setImages] = useState([]);
  const [types, setTypes] = useState([]);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [answersV, setAnswersV] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const projectId = localStorage.getItem('nProject');
  const [answered, setAnswered] = useState([]);
  const [cat, setCat] = useState([]);
  const access_token = localStorage.getItem('access_token');
  const decodedToken = jwtDecode(access_token);
  const userId = decodedToken.userId;

  const token = localStorage.getItem('access_token');
  const [value, setValue] = useState('');
  const [misspelledWords, setMisspelledWords] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 0, left: 0 });
  const [selectedWord, setSelectedWord] = useState(null);
  const [showScrollableDiv, setShowScrollableDiv] = useState(false);
  const [showScrollableDiv2, setShowScrollableDiv2] = useState(false);
  const [viewMode, setViewMode] = useState("AI");
  const [summaries, setSummaries] = useState([]);
  const [originalAnswer, setOriginalAnswer] = useState("");
  const [combinedAnswer, setCombinedAnswer] = useState("");
  const [aiUsed, setAiUsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleToggle = () => {
    setShowScrollableDiv(!showScrollableDiv);
  };

  const handleToggleSh = () => {
    setShowScrollableDiv2(!showScrollableDiv2);
  };

  const [formData, setFormData] = useState({
    summary: '',
  });

  //  useEffect(() => {

  //    fetchAnswerCut(); // Call the function to fetch the unanswered question
  //  }, [category, subCategory, projectId]);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/hub/types`);
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching types:', error);
        // setError('Failed to fetch types');
        setLoading(false);
      }
    };

    fetchTypes();
  }, []);

const fetchAnswers = async () => {
  try {
    // Start loading state
    setLoading(true);
    setError(null);

    // Fetch data from the API
    const response = await fetch(
      `${API_BASE_URL}/api/pdf/end/${projectId}/${phase}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle non-200 responses
    if (!response.ok) {
      throw new Error(`Failed to fetch summary: ${response.statusText}`);
    }

    // Parse the response JSON
    const dataS = await response.json();
    console.log("Fetched Data:", dataS);

    // Destructure the relevant fields from the response
    const {
      summary = "",
      ai_generated = "",
      ai_used = false,
      questionType = "",
      _id = {},
    } = dataS.data;

    // Extract the _id.$oid field safely
    const id = _id.$oid || "no-id";

    // Update state variables for the editor and summaries
    setCombinedAnswer(ai_generated || summary);
    setOriginalAnswer(summary || "No summary available.");
    setAiUsed(ai_used);

    // Format the questionType for display purposes
    const formattedSubType = questionType.replace(/([A-Z])/g, " $1").trim();

    // Update summaries with formatted content
    setSummaries([
      {
        id,
        ai_used,
        ai_generated: `<h2 style="text-align: center;">${formattedSubType}</h2><br>${ai_generated}`,
        summary: `<h2 style="text-align: center;">${formattedSubType}</h2><br>${summary}`,
        questionType: formattedSubType,
      },
    ]);

    console.log("Summary and AI-generated content updated successfully.");
  } catch (error) {
    // Handle and log errors
    console.error("Error fetching answers:", error);
    setError(error.message || "An unknown error occurred.");
  } finally {
    // Ensure loading state is cleared
    setLoading(false);
  }
};



  useEffect(() => {
    fetchAnswers();
  }, [API_BASE_URL, projectId, phase, token]);




  const handleClick = (id) => {
    // Handle click event and set the selected answer
    navigate('/questionEdit/' + id);
  };

  const handleClickSh = (id) => {
    // Handle click event and set the selected answer
    console.log(id)
    handleToggleSh();
    handleToggle();
  };

  const handleEditorChange = () => {
    // Get the current selection range

    const content = editorRef.current.innerHTML;
    const event = { target: { id: 'editor', value: content } };
    // checkSpelling(event.target.innerText);

    // Call the handleChange function to update the state with the new content


    const newText = content || '';
    setCombinedAnswer(content);
    console.log(content);
    console.log("checking error");
    console.log(newText);
    //    checkSpelling(newText);

    handleChange(event);


  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCombinedAnswer(value);
  }



  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image'],
    [{ size: [] }],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];

  const modules = {
    toolbar: {
      container: toolbarOptions,
      // handlers: {
      //   'image': () => {
      //     setShowImagePopup(true);
      //   }
      // }
    },

    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'] // Configure the image resize module
    }
  };
  const module = {

    toolbar: toolbarOptions
  };

  const reactQuillRef = React.useRef(null);
  const accessToolbar = () => {
    // Check if ref is initialized
    if (reactQuillRef.current) {
      // Get the Quill editor instance
      const quill = reactQuillRef.current.getEditor();

      // Access the toolbar
      const toolbar = quill.getModule('toolbar').handlers.image;
      toolbar.addHandler('image', console.log("image toolbar"));
      //console.log(toolbar.handlers.image);

    } else {
      console.error('ReactQuill ref is not initialized');
    }
  };



  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const editorRef = useRef(null);

  const handleImageSelect = (imageUrl) => {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', imageUrl);
    setShowImagePopup(false);
  };

  const formatText = (command, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current.focus();
  };

  const createMarkup = (html) => {
    return { __html: html };
  };
  const insertLink = () => {
    const url = prompt('Enter the link URL:');
    if (url) {
      formatText('createLink', url);
    }
  };

  const insertImage = () => {
    const imageUrl = prompt('Enter the image URL:');
    if (imageUrl) {
      formatText('insertImage', imageUrl);
    }
  };
  const handleInput = () => {
    setCombinedAnswer(editorRef.current.innerHTML);
  };
  useEffect(() => {
    const editor = editorRef.current;
    if (editor && editor.innerHTML !== combinedAnswer) {
      editor.innerHTML = combinedAnswer;
      console.log("in ref");
      console.log(combinedAnswer);
    }
  }, [combinedAnswer]);

  const loadDictionary = async () => {
    const affResponse = await fetch('/dictionaries/en.aff');
    const aff = await affResponse.text();

    const dicResponse = await fetch('/dictionaries/en.dic');
    const dic = await dicResponse.text();
    console.log(dic);
    return nspell({ aff, dic });
  };


  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  };



  const highlightMisspelledWords = () => {
    //if (!editorRef.current) return;


    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const startOffset = range.startOffset;

    const startMarker = document.createElement("span");
    startMarker.id = "start-marker";
    range.insertNode(startMarker);

    console.log(misspelledWords);
    if (misspelledWords.length === 0) {
      console.log("no words")
      //editorRef.current.innerHTML = combinedAnswer;
      return;
    }

    let innerHTML = combinedAnswer;
    const words = innerHTML.split(/(\s+)/); // Split by spaces, keeping the spaces in the array

    //const words = innerHTML.split(/(\b|\s+)/);
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = combinedAnswer;
    const textNodes = getTextNodes(tempContainer);

    textNodes.forEach(node => {
      let nodeText = node.nodeValue;
      misspelledWords.forEach(word => {
        const escapedWord = escapeRegExp(word);
        const regex = new RegExp(`\\b${escapedWord}\\b`, 'g');
        nodeText = nodeText.replace(regex, `<span style="background-color: darkblue; color: white;">${word}</span>`);
      });
      const newNode = document.createElement('span');
      newNode.innerHTML = nodeText;
      node.replaceWith(...newNode.childNodes);
    });



    console.log(tempContainer.innerHTML);
    editorRef.current.innerHTML = tempContainer.innerHTML;
    restoreCursorPosition();

    // Highlight misspelled words
    // for (let i = 0; i < words.length; i++) {
    //   const word = words[i];
    //   if (misspelledWords.includes(word.trim())) {
    //     words[i] = `<span style="background-color: darkblue; color: white;">${word}</span>`;
    //   }
    // }

    // console.log(words);
    // setCombinedAnswer(words);
    //editorRef.current.innerHTML = words.join('');
  };

  const getTextNodes = (node) => {
    let textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node);
    } else {
      for (let child of node.childNodes) {
        textNodes = textNodes.concat(getTextNodes(child));
      }
    }
    return textNodes;
  };

  const restoreCursorPosition = () => {
    const startMarker = document.getElementById("start-marker");
    if (!startMarker) return;

    const range = document.createRange();
    const selection = window.getSelection();

    range.setStartBefore(startMarker);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);

    // Clean up marker
    startMarker.parentNode.removeChild(startMarker);
  };
  const checkSpelling = async (text) => {
    if (!text) return;
    console.log("now checking");
    const spell = await loadDictionary();
    const words = text.split(/\s+/);
    const misspelled = words.filter(word => !spell.correct(word));
    console.log(misspelled);
    setMisspelledWords(misspelled);
    console.log("what was passed");
    console.log(misspelledWords);
    highlightMisspelledWords();

  };

  const showSuggestions = async (word, rect) => {
    const spell = await loadDictionary();
    const suggestions = spell.suggest(word);
    console.log(word);
    console.log(spell);
    console.log(suggestions);
    setSuggestions(suggestions);
    setSuggestionBoxPosition({ top: rect.bottom, left: rect.left });
  };

  const handleWordClick = (word, rect) => {
    console.log("here sugg");
    console.log(word);
    setSelectedWord(word);
    showSuggestions(word, rect);
  };

  const applySuggestion = (suggestion) => {
    // const editor = editorRef.current;
    // const html = editor.innerHTML;
    // const newHtml = html.replace(new RegExp(`\\b${misspelledWords[0]}\\b`, 'g'), suggestion);
    // editor.innerHTML = newHtml;
    // setCombinedAnswer(newHtml);
    // setSuggestions([]);

    if (!selectedWord) return; // Check if a word is selected

    const editor = editorRef.current;
    const html = editor.innerHTML;

    // Create a regex to match only the selected word
    const escapedWord = selectedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const newHtml = html.replace(new RegExp(`\\b${escapedWord}\\b`, 'g'), suggestion);

    editor.innerHTML = newHtml;
    setCombinedAnswer(newHtml);
    checkSpelling(newHtml);
    setSelectedWord(null); // Reset selected word

  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedWord) return; // Check if a word is selected

    const editor = editorRef.current;
    const html = editor.innerHTML;

    // Create a regex to match only the selected word
    const escapedWord = selectedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const newHtml = html.replace(new RegExp(`\\b${escapedWord}\\b`, 'g'), suggestion);

    editor.innerHTML = newHtml;
    setCombinedAnswer(newHtml);
    checkSpelling(newHtml);
    setSelectedWord(null); // Reset selected word
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      // Move the cursor to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editor);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  const handleHeadingChange = (event) => {
    const heading = event.target.value;
    if (heading) {
      formatText('formatBlock', heading);
    }
  };

  const handleImagePopup = () => {
    setShowImagePopup(!showImagePopup);
  };

  const [showHeadingDropdown, setShowHeadingDropdown] = useState(false);

  const toggleHeadingDropdown = () => setShowHeadingDropdown(!showHeadingDropdown);
  //   createOrUpdateSummary();

  const handleInsertFile = (file) => {
    const newFile = API_BASE_URL + '/images/' + file;
    console.log(newFile);
    setCombinedAnswer((prevContent) => `${prevContent}<div contenteditable="true" style="display:inline-block; width:30%;"><img src="${newFile}" style="width:100%;" /></div>`);
    // setCombinedAnswer((prevContent) => `${prevContent}<img src="${newFile}" alt="Inserted File" />`);

  };

  useEffect(() => {
    const fetchSubtypeFiles = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/hub/project/${projectId}`);
        setTypes(response.data.data);
        console.log(response.data);

      } catch (error) {
        console.error('Error fetching files:', error);


      }
    };

    fetchSubtypeFiles();
  }, []);


  const [resizingImage, setResizingImage] = useState(null);
  const [isResizing, setIsResizing] = useState(false);
  const [initialX, setInitialX] = useState(null);
  const [initialY, setInitialY] = useState(null);

  function handleImageResizing(event) {
    if (event.type === 'mousemove' && !isResizing) {
      return; // Ignore mousemove event if not resizing
    }

    const imageElement = event.target;
    if (imageElement.nodeName !== 'IMG') {
      return; // Ignore if the target is not an image element
    }

    if (event.type === 'mousedown') {
      startImageResize(event);
    } else if (event.type === 'mousemove') {
      if (isResizing) {
        resizeImage(event, imageElement);
      }
    } else if (event.type === 'mouseup') {
      stopImageResize();
    }
  }

  function startImageResize(event) {
    setIsResizing(true);
    setInitialX(event.clientX);
    setInitialY(event.clientY);
  }

  function resizeImage(event, imageElement) {
    const newWidth = imageElement.offsetWidth + (event.clientX - initialX);
    const newHeight = imageElement.offsetHeight + (event.clientY - initialY);
    imageElement.style.width = `${newWidth}px`;
    imageElement.style.height = `${newHeight}px`;
  }

  function stopImageResize() {
    setIsResizing(false);
    setInitialX(null);
    setInitialY(null);
  }






  const handleMouseDown = (event) => {
    if (event.target.tagName === 'IMG') {
      setResizingImage(event.target);
      setInitialX(event.clientX);
      setInitialY(event.clientY);
      setIsResizing(true);
    }
  };

  const handleMouseMove = (event) => {
    if (isResizing && resizingImage) {
      const deltaX = event.clientX - initialX;
      const deltaY = event.clientY - initialY;
      const newWidth = resizingImage.clientWidth + deltaX;
      const newHeight = resizingImage.clientHeight + deltaY;
      resizingImage.style.width = `${newWidth}px`;
      resizingImage.style.height = `${newHeight}px`;
      setInitialX(event.clientX);
      setInitialY(event.clientY);
    }
  };

  const handleMouseUp = () => {
    if (isResizing) {
      setIsResizing(false);
      setResizingImage(null);
    }
  };

  const generateAi = async () => {
    setGenerateLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/pdf/end/${projectId}/${phase}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.status === 200 && data.data) {
        console.log("AI summaries generated successfully");
        setAnswers(data.data);

        const combined = data.data
          .map((answer) => {
            const formattedSubType = answer.questionType.replace(/([A-Z])/g, ' $1').trim();
            return `<h2 style="text-align: center;">${formattedSubType}</h2><br>${answer.ai_generated}`;
          })
          .join('<br><br>');

        setCombinedAnswer(combined);
        setAiUsed(true);
        setViewMode("AI"); 
      } else {
        console.error("Failed to generate AI summaries:", data.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error during AI generation:", error);
    } finally {
      setGenerateLoading(false);
    }
  };

  useEffect(() => {
    const fetchVideo = async () => {
      try {

        const response = await fetch(`${API_BASE_URL}/api/ourVideo/video/${phase}/end/${userId}/${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },

        });
        if (response.status === 200) {
          const data = await response.json();

          if (data.status == 'still watching') {
            console.log(data);
            console.log(data.video._id);
            const newLink = data.video.videolink.replace('https://youtu.be/', '');
            setActiveLink(newLink); // Assuming the video link is in the videolink field
            setActiveId(data.video._id);
            setActiveTime(data.video.watchTime)// Assuming the video link is in the videolink field
            setIsOpen(true); // Open the modal if a video is found
          } else {
            console.error('Video already watched or not found');
          }
        } else {
          console.error('Failed to fetch video. Status code:', response.status);
        }
      } catch (error) {
        console.error('Failed to fetch video:', error);
      }
    };

    fetchVideo();
  }, [phase]);


  return (





    <div className='container2'>
      {phase === 'Ideation' && <SideMenu2 />}
      {phase === 'ProductDefinition' && <SideMenu2P />}
      {phase === 'InitialDesign' && <SideMenu2I />}
      {phase === 'Commercialization' && <SideMenu2C />}
      {phase === 'ValidatingAndTesting' && <SideMenu2V />}

      <div className="main-content">

        <HeaderIdeation />
        <div className={`main-content2 ${showScrollableDiv ? 'shrink' : ''}`}>

          <div className='text-center'>
            <p className='textHp'>Pdf Summary</p>
            <p>Here you have the summary of all your answers to our questions</p>
          </div>
          <div className="w-fit mr-[20px] ml-auto mb-[10px] " >
          </div>
          <div className="quiInt">
            {loading && <p>Loading...</p>}
            {error && <p className="error-text">{error}</p>}

            {!loading && !error && (
              <>
                {/* Regenerate with AI Button */}
                {aiUsed && viewMode === "AI" && (
                  <div className="ml-auto w-fit">
                    <button
                      onClick={generateAi}
                      className={`rounded-lg px-3 py-1 text-[18px] bg-[#FFD700] mb-3`}
                    >
                      Regenerate with AI
                    </button>
                  </div>
                )}

                {/* Main Editor */}
                <form>
                  <div className="container-textBs">
                    <div
                      ref={editorRef}
                      contentEditable={false}
                      className="editor bg-[#EEEEEE] md:w-[80%] min-h-72 rounded-md m-auto"
                      style={{
                        whiteSpace: "pre-wrap",
                        minHeight: "200px",
                        border: "1px solid #ccc",
                        padding: "10px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: viewMode === "Original"
                          ? originalAnswer
                          : combinedAnswer || "<p>No content available</p>",
                      }}
                    />
                  </div>
                </form>

                {/* Action Buttons */}
                <div className="w-fit m-auto mt-5">
                  {!aiUsed ? (
                    <button
                      onClick={() => {
                        generateAi();
                        setViewMode("AI");
                      }}
                      className={`rounded-lg px-3 py-1 text-[18px] bg-[#FFD700]`}
                    >
                      Generate with AI
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        setViewMode(viewMode === "AI" ? "Original" : "AI")
                      }
                      className="rounded-lg px-3 py-1 text-[18px] bg-[#FFD700] m-2"
                    >
                      {viewMode === "Original"
                        ? "View Generated AI Document"
                        : "View Original Document"}
                    </button>
                  )}
                </div>

                {/* Summaries */}
                {/* <div className="summary-content">
                  {summaries.map(({ id, ai_used, ai_generated, summary, questionType }) => (
                    <div key={id}>
                      <h3>{questionType}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: viewMode === "AI" && ai_used ? ai_generated : summary,
                        }}
                      />
                    </div>
                  ))}
                </div> */}
              </>
            )}
          </div>
        </div>

        <div className={`scrollable-div ${showScrollableDiv ? 'show' : ''}`}>
          <button className="close-button" onClick={handleToggle}>X</button>
          {(answered || []).map((answered, index) => (
            <div className='qulis' key={index} onClick={() => handleClick(answered._id)} style={{ cursor: 'pointer' }}>
              <p style={{ marginBottom: 7 }}>{answered.questionId.question}</p>
            </div>
          ))}

          {/* Add more content as needed */}
        </div>

        <div className={`scrollable-div ${showScrollableDiv2 ? 'show' : ''}`}>
          <button className="close-button" onClick={handleToggleSh}>X</button>


          {(cat || []).map((cat, index) => (
            <div className='qulis' key={index} onClick={() => handleClickSh(cat.questionSubType)} style={{ cursor: 'pointer' }}>
              <p style={{ marginBottom: 7 }}>{cat.questionSubType}</p>
            </div>
          ))}

        </div>

      </div>
      <ModalVideo open={isOpen} onClose={() => setIsOpen(false)} link={activeLink} id={activeId} time={activeTime}>

      </ModalVideo>

      <Toaster position="top-right" />
    </div>

  );
}




export default QuestionBusIntro;
